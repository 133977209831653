<script setup lang="ts">
import { computed } from 'vue';

type ButtonIconSize = 'sm' | 'md';
type ButtonIconTheme = 'dark' | 'light' | 'light-border' | 'gray' | 'primary';

const props = withDefaults(
  defineProps<{
    theme: ButtonIconTheme;
    label: string;
    size?: ButtonIconSize;
    tabindex?: number;
    disabled?: boolean;
    active?: boolean;
  }>(),
  {
    theme: 'dark',
    tabindex: undefined,
    size: 'md',
    disabled: false,
    active: false
  }
);

defineEmits(['click']);

const buttonThemeClasses = new Map<ButtonIconTheme, string>([
  ['light', 'hb-btn-icon--light'],
  ['light-border', 'hb-btn-icon--light-border'],
  ['dark', 'hb-btn-icon--dark'],
  ['gray', 'hb-btn-icon--gray'],
  ['primary', 'hb-btn-icon--primary']
]);

const buttonSizeClasses = new Map<ButtonIconSize, string>([
  ['sm', 'hb-btn-icon--sm'],
  ['md', 'hb-btn-icon--md']
]);

const activeClass = computed(() => {
  return props.active ? 'is-active' : '';
});

const classes = computed(() => {
  return [
    buttonThemeClasses.get(props.theme),
    buttonSizeClasses.get(props.size),
    activeClass.value
  ];
});
</script>

<template>
  <button
    class="hb-btn-icon"
    :class="classes"
    :arial-label="label"
    :title="label"
    :tabindex="tabindex"
    type="button"
    role="button"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot name="default" />
  </button>
</template>
