import { onClickOutside, useMagicKeys } from '@vueuse/core';
import { lock, unlock } from 'tua-body-scroll-lock';
import { nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';

export type UseModalEmitType = {
  'update:active': [value: boolean];
};

export const useModal = (
  props: {
    active: boolean;
    closeOnEscapeKey?: boolean;
    closeOnClickOutside?: boolean;
  },
  emit: (evt: 'update:active', value: boolean) => void
) => {
  const modalClickOutsideRef = ref<HTMLElement | null>(null);
  const modalScrollRef = ref<HTMLElement | null>(null);
  const { escape } = useMagicKeys();

  const close = () => emit('update:active', false);

  onClickOutside(modalClickOutsideRef, () => {
    (props.closeOnClickOutside ?? true) && close();
  });

  // Cleanup scroll locks
  onBeforeUnmount(() => unlock(modalScrollRef.value));

  // Activate scroll lock if component is mounted with active=true
  onMounted(() => props.active ?? lock(modalScrollRef.value));

  watch(escape, (v) => {
    if ((props.closeOnEscapeKey ?? true) && v) close();
  });

  watch(
    () => props.active,
    (newVal) => {
      if (newVal) {
        // Scrollable element is only mounted when props.active is true
        // so awaiting the elements to exist
        nextTick(() => {
          lock(modalScrollRef.value);
        });
      } else {
        unlock(modalScrollRef.value);
      }
    }
  );

  return {
    modalClickOutsideRef,
    modalScrollRef,
    close
  };
};
