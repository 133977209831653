<script setup lang="ts">
import { computed, useSlots } from 'vue';

import ButtonIcon from '@/shared/components/UI/Buttons/ButtonIcon.vue';
import IconX from '@/shared/components/UI/Icons/IconX.vue';
import { useModal, UseModalEmitType } from '@/shared/composables/modal';

type ModalSize = 'sm' | 'md' | 'lg' | 'xl';

const slots = useSlots();

const emit = defineEmits<UseModalEmitType>();

const props = withDefaults(
  defineProps<{
    active: boolean;
    size?: ModalSize;
    closeButton?: boolean;
    closeLabel?: string;
    closeOnEscapeKey?: boolean;
    closeOnClickOutside?: boolean;
    alwaysOnTop?: boolean;
  }>(),
  {
    size: 'md',
    closeButton: true,
    closeLabel: 'Close',
    closeOnEscapeKey: true,
    closeOnClickOutside: true,
    alwaysOnTop: false
  }
);

const { modalClickOutsideRef, modalScrollRef, close } = useModal(props, emit);

const modalSizeClasses = new Map<ModalSize, string>([
  ['sm', 'max-w-sm'],
  ['md', 'max-w-lg'],
  ['lg', 'max-w-2xl'],
  ['xl', 'max-w-4xl']
]);

const modalZindex = computed(() => (props.alwaysOnTop ? 'z-30' : 'z-modal'));

const classes = computed(() => modalSizeClasses.get(props.size));
</script>

<template>
  <!-- Backdrop -->
  <Teleport to="body">
    <Transition
      enter-from-class="opacity-0"
      enter-active-class="transition-opacity duration-300 ease-in-out"
      leave-active-class="transition-opacity duration-300 ease-in-out"
      leave-to-class="opacity-0"
    >
      <div
        v-if="active"
        aria-hidden="true"
        class="fixed left-0 top-0 h-lvh w-full bg-black/30"
        :class="modalZindex"
      ></div>
    </Transition>

    <Transition
      enter-from-class="translate-y-20 opacity-0"
      enter-active-class="transition-all duration-300 ease-in-out"
      leave-active-class="transition-all duration-300 ease-in-out"
      leave-to-class="translate-y-20 opacity-0"
    >
      <div
        v-if="active"
        aria-labelledby="modal-body"
        aria-modal="true"
        role="dialog"
        class="fixed left-0 top-0 flex h-lvh w-full items-center justify-center"
        :class="modalZindex"
      >
        <div
          ref="modalClickOutsideRef"
          :class="classes"
          class="relative mx-2 max-h-[90dvh] w-full overflow-hidden rounded-lg bg-white shadow-xl md:mx-8"
        >
          <ButtonIcon
            v-if="closeButton"
            theme="light"
            :label="closeLabel"
            :aria-label="closeLabel"
            class="absolute right-3 top-3 z-10"
            @click="close"
            ><IconX
          /></ButtonIcon>

          <div
            ref="modalScrollRef"
            class="h-full max-h-[90dvh] overflow-y-auto"
          >
            <div
              v-if="slots.header"
              class="flex items-center justify-between gap-2 px-4 pt-4 md:px-6 md:pt-6"
            >
              <slot name="header" />
            </div>

            <div v-if="slots.body" class="p-4 md:p-6">
              <slot name="body" />
            </div>
            <template v-if="slots.default">
              <slot name="default" />
            </template>
            <div v-if="slots.footer">
              <div class="border-t p-4 md:px-6">
                <slot name="footer" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>
