<script setup>
const showCountrySelector = ref(false);
import '/node_modules/flag-icons/css/flag-icons.min.css';

import { computed, ref } from 'vue';

import Modal from '@/shared/components/UI/Modal.vue';
import { useTranslations } from '@/shared/composables/useTranslations.ts';

import IconChevron from './Icons/IconChevron.vue';

const { t } = useTranslations();

const props = defineProps({
  countryConfigs: {
    type: Array,
    required: true
  },
  cartCurrency: {
    type: String,
    required: true
  }
});
const currentCountryCode = window.Shopify.country.toLowerCase();

// preserve theme id across countries, if not the main theme.
const getStoreEndpoint = (url) =>
  window.Shopify.theme.role !== 'main'
    ? `${url}?preview_theme_id=${window.Shopify.theme.id}`
    : url;

const getCountrySwitcherUrl = (countryConfig) =>
  countryConfig.shopifyConfig?.storeEndpoint
    ? getStoreEndpoint(countryConfig.shopifyConfig.storeEndpoint)
    : `${countryConfig.legacyStore?.url}common/country_switcher/redirect?country=${countryConfig.countryCode}`;

const countries = computed(() => {
  const mapped = props.countryConfigs.map((countryConfig) => ({
    code: countryConfig.countryCode,
    name: t(`global.countries.${countryConfig.countryCode.toLowerCase()}`),
    url: getCountrySwitcherUrl(countryConfig)
  }));

  mapped.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  return mapped;
});

const getFlagClass = (country) =>
  country ? `fi fi-${country.toLowerCase()}` : '';
</script>

<template>
  <button
    class="flex items-center gap-1.5 rounded-md border border-transparent py-2 text-white hover:text-primary-200"
    @click="showCountrySelector = !showCountrySelector"
  >
    <span
      :class="getFlagClass(currentCountryCode)"
      class="shrink-0 rounded text-lg"
    ></span>
    <span class="font-bold underline"
      >{{ t(`global.countries.${currentCountryCode}`) }} ({{
        cartCurrency
      }})</span
    >
    <IconChevron class="-ml-1 text-xl" />
  </button>

  <Modal v-model:active="showCountrySelector" size="lg">
    <template #header>
      <div class="flex w-full justify-center text-xl font-bold">
        {{ t('footer.country_switcher.change_country') }}
      </div>
    </template>
    <template #body>
      <div class="columns-2 space-y-1 md:columns-3">
        <template v-for="country in countries" :key="country.countryCode">
          <a
            :href="country.url"
            class="flex items-center gap-2 rounded-md p-2 text-sm transition-colors duration-100 hover:bg-gray-100 hover:text-secondary hover:no-underline"
          >
            <span
              :class="getFlagClass(country.code)"
              class="shrink-0 rounded text-lg"
              :title="country.name"
            ></span>

            <span>{{ country.name }}</span>
          </a>
        </template>
      </div>
    </template>
  </Modal>
</template>
